import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "~/utils";

const textVariants = cva("transition-colors", {
  variants: {
    font: {
      serif: "font-serif",
      sans: "font-sans",
    },
    fontWeights: {
      bold: "font-bold",
      normal: "font-normal",
      semiBold: "font-semibold",
      light: "font-light",
    },
    fontStyle: {
      italic: "italic",
    },
    variant: {
      h1: "scroll-m-20 text-2xl lg:text-4xl font-bold tracking-tight",
      h2: "scroll-m-20 text-xl lg:text-2xl font-bold tracking-tight mb-2",
      h3: "scroll-m-20 text-lg lg:text-xl font-bold tracking-tight",
      h4: "scroll-m-20 text-md lg:text-lg font-bold tracking-tight",
      h5: "scroll-m-20 text-sm lg:text-md font-bold tracking-tight",
      p: "text-base lg:text-lg",
      small: "text-sm",
      "extra-small": "text-xs",
      "extra-large": "text-xl",
      medium: "text-md",
      large: "text-lg",
    },
    color: {
      primary: "text-primary",
      brand: "text-brand",
      accent: "text-accent",
      default: "text-gray-1000",
      muted: "text-gray-600",
      black: "text-black",
      "muted-2": "text-gray-700",
    },
  },
  defaultVariants: {
    variant: "p",
    color: "default",
    font: "sans",
  },
});

type TextTypes =
  | React.HTMLAttributes<HTMLHeadingElement>
  | React.HTMLAttributes<HTMLParagraphElement>
  | React.HTMLAttributes<HTMLQuoteElement>;
export type TextProps = TextTypes & VariantProps<typeof textVariants>;

export function Text({
  className,
  variant,
  color,
  fontWeights,
  fontStyle,
  children,
  font,
  ...props
}: TextProps) {
  const elements = ["h1", "h2", "h3", "h4", "h5", "p"];
  const element = elements.find((el) => el === variant) || "p";

  return React.createElement(
    element,
    {
      className: cn(
        textVariants({
          font,
          variant,
          color,
          fontWeights,
          fontStyle,
          className,
        }),
      ),
      ...props,
    },
    children,
  );
}
